<template>
    <div class="w-full h-full">
        <header class="border-b">
            <nav class="px-4 flex justify-between w-ful py-4">
                <router-link to="/">
                    <svg width="150" height="48" viewBox="0 0 671 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2262_30216)">
                            <path
                                d="M72.94 0C32.66 0 0 32.66 0 72.94C0 78.03 0.52 83 1.52 87.79C2.28 72.54 8.58 58.31 19.46 47.43C31.08 35.81 46.53 29.41 62.96 29.41C79.39 29.41 94.84 35.81 106.46 47.43C118.08 59.05 124.48 74.5 124.48 90.93C124.48 107.36 118.08 122.81 106.46 134.43C103.89 137 101.13 139.32 98.21 141.36C126.03 131.08 145.87 104.32 145.87 72.92C145.87 32.66 113.22 0 72.94 0Z"
                                fill="#FF4363" />
                            <path
                                d="M91.8659 106.77C92.9769 86.7085 77.6147 69.5448 57.5533 68.4338C37.4919 67.3227 20.3283 82.685 19.2172 102.746C18.1061 122.808 33.4684 139.971 53.5298 141.082C73.5912 142.194 90.7548 126.831 91.8659 106.77Z"
                                fill="#FF4363" />
                            <path
                                d="M117.53 90.94C117.53 60.8 93.1 36.37 62.96 36.37C32.82 36.37 8.38 60.8 8.38 90.94C8.38 98.74 10.02 106.15 12.96 112.86C12.46 110.22 12.21 107.51 12.21 104.77C12.21 93.2 16.72 82.31 24.9 74.13C33.08 65.95 43.97 61.44 55.54 61.44C67.11 61.44 78 65.95 86.18 74.13C94.36 82.31 98.87 93.2 98.87 104.77C98.87 116.34 94.36 127.23 86.18 135.41C82.17 139.42 77.5 142.55 72.43 144.7C98.05 140.21 117.53 117.86 117.53 90.95V90.94Z"
                                fill="#FF4363" />
                            <path
                                d="M185.83 102.6C187.24 112.49 195.56 119.08 206.24 119.08C213.3 119.08 217.86 116.41 222.09 110.92H248.77C240.45 129.91 224.6 141.06 206.23 141.06C181.12 141.06 160.08 120.81 160.08 94.91C160.08 69.01 180.17 48.13 205.76 48.13C231.35 48.13 251.75 68.69 251.75 95.69C251.75 98.2 251.59 100.24 251.12 102.6H185.82H185.83ZM225.7 85.02C223.66 76.07 215.81 70.11 205.76 70.11C196.34 70.11 188.18 75.76 186.14 85.02H225.7Z"
                                fill="#FF4363" />
                            <path
                                d="M265.96 141.06V51.61H291.1V61.12H291.42C296.09 51.61 303.51 48.55 313.82 48.55V75.47C297.86 75.63 292.71 81.59 292.71 91.75V141.07H265.96V141.06Z"
                                fill="#FF4363" />
                            <path
                                d="M438.59 141.06V92.87C438.59 78.85 434.08 73.69 424.89 73.69C414.74 73.69 410.06 79.81 410.06 91.58V141.06H383.31V91.74C383.31 80.14 379.12 73.69 369.93 73.69C359.45 73.69 354.78 80.46 354.78 92.87V141.06H328.03V51.61H353.01V61.12H353.33C358 52.74 366.55 48.55 377.99 48.55C389.43 48.55 398.14 54.35 403.78 64.51C410.23 54.36 419.9 48.55 432.14 48.55C452.12 48.55 465.34 60.8 465.34 85.78V141.06H438.59Z"
                                fill="#FF4363" />
                            <path
                                d="M479.55 141.06V51.61H506.3V141.06H479.55ZM506.3 35.18C506.3 27.79 500.31 21.8 492.92 21.8C485.53 21.8 479.54 27.79 479.54 35.18C479.54 42.57 485.53 48.56 492.92 48.56C500.31 48.56 506.3 42.57 506.3 35.18Z"
                                fill="#FF4363" />
                            <path
                                d="M533.73 141.06V71.92H520.51V51.61H533.73V21.8H560.48V51.61H573.37V71.92H560.48V141.06H533.73Z"
                                fill="#FF4363" />
                            <path
                                d="M643.67 141.06V91.91C643.67 80.31 639.16 73.7 629.33 73.7C619.5 73.7 614.34 79.66 614.34 90.78V141.06H587.59V21.8H614.34V60.16C618.21 52.91 626.91 48.56 637.55 48.56C658.02 48.56 670.43 61.45 670.43 84.82V141.07H643.68L643.67 141.06Z"
                                fill="#FF4363" />
                        </g>
                        <defs>
                            <clipPath id="clip0_2262_30216">
                                <rect width="670.42" height="144.69" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </router-link>
                <div class="md:items-center md:w-auto flex">
                    <div class="flex text-sm"></div>
                </div>
            </nav>
        </header>
        <main class="container mx-auto mb-24">
            <slot />
        </main>
    </div>
</template>

<script setup lang="ts">

useHead({
    title: 'Ermith',
    bodyAttrs: {
        class: 'bg-[#f5f3f1]'
    }
})

</script>